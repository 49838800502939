import { AnyAction } from "redux";

import { EteamsState } from "./types";
import { parseAPIResponse, apiCall } from "../../services/api";
import _ from "lodash";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/nextjs";
import { ReducerType } from "../rootReducer";
import { tokenErrorText } from "../../constants";
import MainActions from "../main";

const defaultState: EteamsState = {
    isLoading: false,
    meetAndGreetRegisterData: null,
};

const eteams_get_campus_meet_greet = createAsyncThunk(
    "eTeams/fetchCampuseTeams",
    async (params: { campusName: string }) => {
        return apiCall(
            `/v2/event/getEventsByCampus?campus_name=${params.campusName}&record_type=volunteer_orientation`,
            "GET"
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })

            .then((data) => {
                if (data && data.data) {
                    return data.data;
                } else {
                    return {};
                }
            });
    }
);

const eteams_register_user_meet_greet = createAsyncThunk(
    "eTeams/fetchRegisterUsereTeam",
    async (
        params: {
            contactId: string;
            jobId: string;
            shiftId: string;
            callback?: Function;
        },
        { getState, dispatch }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        const dataToSend = new FormData();

        dataToSend.append("contact_id", params.contactId);
        dataToSend.append("event_id", params.shiftId);

        return apiCall(
            "/v2/event/addContactToEvent",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })

            .then((data) => {
                if (data && data.data) {
                    if (params.callback && _.isFunction(params.callback)) {
                        params.callback();
                    }

                    return data.data;
                } else {
                    return {};
                }
            });
    }
);

const eTeamsStore = createSlice({
    name: "eTeams",
    initialState: defaultState,
    reducers: {
        main_loading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(
                eteams_get_campus_meet_greet.fulfilled,
                (state, action) => {
                    state.meetAndGreetRegisterData = action.payload;
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("pending") &&
                        action.type.indexOf("eTeams") > -1
                    );
                },
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("rejected") &&
                        action.type.indexOf("eTeams") > -1
                    );
                },
                (state) => {
                    state.isLoading = false;
                }
            ),
});

export const actions = {
    eteams_register_user_meet_greet,
    eteams_get_campus_meet_greet,
    ...eTeamsStore.actions,
};

export default eTeamsStore;
