import { AnyAction } from "redux";

import { WatchPartyState } from "./types";
import { parseAPIResponse, apiCall } from "../../services/api";
import _ from "lodash";
import * as Sentry from "@sentry/nextjs";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReducerType } from "../rootReducer";
import { tokenErrorText } from "../../constants";
import MainActions from "../main";

const defaultState: WatchPartyState = {
    isLoading: false,
    eventsData: null,
    joinWatchPartyResponseError: null,
};

const watch_party_get_campus_orientation = createAsyncThunk(
    "watchParty/fetchCampusWatchPartyOrientation",
    async () => {
        return apiCall(
            "/v2/event/getEventsByCampus?campus_name=Watch+Party&record_type=leadership_orientation&leadership_orientation_meeting_type=interest",
            "GET"
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })

            .then((data) => {
                if (data && data.data) {
                    return data.data;
                } else {
                    return {};
                }
            });
    }
);

const watch_party_register_orientation = createAsyncThunk(
    "watchParty/fetchRegisterUserWatchPartyOrientation",
    async (
        params: { contactId: string; eventId: string; callback?: Function },
        { getState, dispatch }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        const dataToSend = new FormData();

        dataToSend.append("contact_id", params.contactId);
        dataToSend.append("event_id", params.eventId);

        return apiCall(
            "/v2/event/addContactToEvent",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })

            .then((data) => {
                if (data && data.data) {
                    if (params.callback && _.isFunction(params.callback)) {
                        params.callback();
                    }

                    return data.data;
                } else {
                    return {};
                }
            });
    }
);

const watch_party_register = createAsyncThunk(
    "watchParty/fetchRegisterUserWatchParty",
    async (
        params: {
            contactId: string;
            watchPartyId: string;
            callback?: Function;
        },
        { getState, dispatch }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        const dataToSend = new FormData();

        dataToSend.append("contactId", params.contactId);
        dataToSend.append("watchPartyId", params.watchPartyId);

        return apiCall(
            "/v2/watchParty/addContactToWatchParty",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })

            .then((response) => {
                if (response && response.data) {
                    if (
                        params.callback &&
                        _.isFunction(params.callback) &&
                        response.status_code === 200
                    ) {
                        params.callback();
                    }

                    return { ...response.data, isError: false };
                } else {
                    return { ...response, isError: true };
                }
            });
    }
);

const watchPartyStore = createSlice({
    name: "watchParty",
    initialState: defaultState,
    reducers: {
        main_loading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(
                watch_party_get_campus_orientation.fulfilled,
                (state, action) => {
                    state.eventsData = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(watch_party_register.fulfilled, (state, action) => {
                const data = action.payload;

                if (data.isError) {
                    state.joinWatchPartyResponseError = data;
                }

                state.isLoading = false;
            })
            .addCase(watch_party_register.pending, (state) => {
                state.joinWatchPartyResponseError = null;
            })
            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("pending") &&
                        action.type.indexOf("watchParty") > -1
                    );
                },
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("rejected") &&
                        action.type.indexOf("watchParty") > -1
                    );
                },
                (state) => {
                    state.isLoading = false;
                }
            ),
});

export const actions = {
    watch_party_register_orientation,
    watch_party_get_campus_orientation,
    watch_party_register,
    ...watchPartyStore.actions,
};

export default watchPartyStore;
