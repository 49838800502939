import {
    EgroupsState,
    eGroupRequest,
    eGroupFilter,
    Demographic,
    Language,
} from "./types";
import {
    AnyAction,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/nextjs";
import { apiCall, parseAPIResponse } from "../../services/api";
import { ObjectLiteral, tokenErrorText } from "../../constants";
import _ from "lodash";
import {
    formatEgroupData,
    formatEgroups,
    formatEgroupType,
    formatCollection,
    formateGroupOrientations,
    formatEgroupDemographic,
    formatEgroupLanguage,
    formatEgroupFilters,
    formatEgroupPageFilter,
} from "./formatter";
import { ReducerType } from "../rootReducer";
import MainActions from "../main";
import { EventOrientation } from "../../globalTypes";

const defaultState: EgroupsState = {
    isLoading: false,
    egroups: [],
    eGroupRequests: [],
    egroupsFormatted: [],
    isLoadingGroups: false,
    isLoadingCampuses: false,
    isLoadingFilters: false,
    egroupsDemographicList: [],
    leaderEventsCampusData: [],
    eGroupTypes: [],
    eGroupData: null,
    eGroupLeaderEventRegisterData: null,
    eGroupError: null,
    eGroupLeaderEgroups: [],
    eGroupLeaderEditSuccess: false,
    eGroupLeaderEditResponse: null,
    eGroupLeaderContactEditSuccess: false,
    eGroupLeaderContactEditResponse: null,
    eGroupHomeData: undefined,
    isLoadingEgroups: [],
    egroupCollections: [],
    isLoadingCollections: false,
    egroupCollectionsSuccessTimestamp: 0,
    joineGroupResponseError: null,
    eGroupLanguages: [],
    eGroupFilters: [],
    eGroupDefaultFilters: [],
};

//actions

///*********************** NEW API *********************/
const egroupsGetHomeByUrl = createAsyncThunk(
    "eGroup/fetchGetHomeByUrl",
    async (params: { url: string; disableCampusCheck?: boolean }) => {
        return apiCall(
            `/v1/egroups/pages?url=${params.url}${
                params.disableCampusCheck === true ? "&checkCampuses=false" : ""
            }`,
            "GET",
            undefined,
            undefined,
            undefined,
            `${process.env.NEXT_PUBLIC_NEW_API_DOMAIN}`
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response.content) {
                    return {
                        url: response.url,
                        requiredQuery: response.requiredQuery,
                        title: response.title,
                        description: response.description,
                        name: response.name,
                        templateFilters: formatEgroupPageFilter(
                            response.content.filters.slice(0)
                        ),
                        templateSections: response.content.sections,
                        templateCollections: response.content.collections,
                        loadTime: Date.now(),
                        loadError: false,
                    };
                } else {
                    return {
                        loadTime: Date.now(),
                        loadError: true,
                    };
                }
            })
            .catch(() => {
                return {
                    loadTime: Date.now(),
                    loadError: true,
                };
            });
    }
);

const egroupsGetEgroups = createAsyncThunk<
    eGroupRequest,
    {
        querystring: string;
        requestId: string;
        loadFilters: boolean;
    }
>(
    "eGroup/fetcheGroups",
    async (params: {
        querystring: string;
        requestId: string;
        loadFilters: boolean;
    }) => {
        return apiCall(
            "/v1/egroups/?" + (params.querystring || ""),
            "GET",
            undefined,
            undefined,
            undefined,
            `${process.env.NEXT_PUBLIC_NEW_API_DOMAIN}`
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response) {
                    const responseObj: eGroupRequest = {
                        egroupsList: _.isArray(response.eGroups)
                            ? formatEgroups(response.eGroups)
                            : [],
                        requestId: params.requestId,
                        successTimestamp: Date.now(),
                        count: response.count || 0,
                    };

                    if (params.loadFilters) {
                        responseObj.egroupsFilterList = _.isArray(
                            response.filters
                        )
                            ? formatEgroupFilters(response.filters)
                            : [];

                        responseObj.egroupsDefaultFilterList = _.isArray(
                            response.defaultFilters
                        )
                            ? formatEgroupFilters(response.defaultFilters)
                            : [];
                    }

                    return responseObj;
                } else {
                    return {
                        egroupsList: [],
                        egroupsFilterList: [],
                        egroupsDefaultFilterList: [],
                        requestId: params.requestId,
                        successTimestamp: Date.now(),
                        count: 0,
                    };
                }
            });
    }
);

const egroupsGetEgroupsFilters = createAsyncThunk<
    {
        egroupsFilterList: eGroupFilter[];
        egroupsDefaultFilterList: eGroupFilter[];
        successTimestamp: number;
    },
    { querystring: string }
>("eGroup/fetcheGroupsFilters", async (params: { querystring: string }) => {
    return apiCall(
        "/v1/egroups/filters?" + (params.querystring || ""),
        "GET",
        undefined,
        undefined,
        undefined,
        `${process.env.NEXT_PUBLIC_NEW_API_DOMAIN}`
    )
        .then(parseAPIResponse, (error) => {
            Sentry.captureMessage(error);
        })
        .then((response) => {
            if (response) {
                return {
                    egroupsFilterList: _.isArray(response.filters)
                        ? formatEgroupFilters(response.filters)
                        : [],
                    egroupsDefaultFilterList: _.isArray(response.defaultFilters)
                        ? formatEgroupFilters(response.defaultFilters)
                        : [],
                    successTimestamp: Date.now(),
                };
            } else {
                return {
                    egroupsFilterList: [],
                    egroupsDefaultFilterList: [],
                    successTimestamp: Date.now(),
                };
            }
        });
});

const egroups_get_egroup = createAsyncThunk(
    "eGroup/fetchGeteGroup",
    async (egroupId: string, { rejectWithValue }) => {
        return apiCall(
            `/v1/egroups/find/${egroupId}`,
            "GET",
            undefined,
            undefined,
            undefined,
            `${process.env.NEXT_PUBLIC_NEW_API_DOMAIN}`
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.id) {
                    const eGroupData = formatEgroupData(response);

                    return eGroupData;
                } else if (response && response.error) {
                    return rejectWithValue({ error: response.error });
                } else {
                    return null;
                }
            });
    }
);

/************** OLD API *********/

const egroups_get_all_egroups = createAsyncThunk<eGroupRequest, string>(
    "eGroup/fetchAlleGroups",
    async (requestId: string) => {
        return apiCall("/v2/egroup/getAlleGroups")
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.data) {
                    return {
                        egroupsList: formatEgroups(response.data),
                        egroupsFilterList: [],
                        requestId: requestId,
                        successTimestamp: Date.now(),
                        count:
                            response.data.length > 0 ? response.data.length : 0,
                    };
                } else {
                    return {
                        egroupsList: [],
                        egroupsFilterList: [],
                        requestId: requestId,
                        successTimestamp: Date.now(),
                        count: 0,
                    };
                }
            });
    }
);

const egroups_get_all_egroup_demographics = createAsyncThunk<Demographic[]>(
    "eGroup/fetchDemographicTypes",
    async () => {
        return apiCall("/v2/commonMethods/getDemographicList")
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.data) {
                    return formatEgroupDemographic(response.data);
                } else {
                    return [];
                }
            });
    }
);

const egroups_get_all_egroup_languages = createAsyncThunk<Language[]>(
    "eGroup/fetchLanguages",
    async () => {
        return apiCall("/v2/egroup/egroupLanguages")
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.data) {
                    return formatEgroupLanguage(response.data);
                } else {
                    return [];
                }
            });
    }
);

const egroups_join_egroup = createAsyncThunk(
    "eGroup/fetchJoineGroup",
    async (
        params: {
            eGroupId: string;
            userId: string;
            userExtraData?: ObjectLiteral;
            callback?: Function;
        },
        { getState, dispatch }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        const dataToSend = new FormData();
        dataToSend.append("egroup_id", params.eGroupId);
        dataToSend.append("contact_id", params.userId);

        if (
            params.userExtraData &&
            params.userExtraData.parentFirstName &&
            params.userExtraData.parentLastName &&
            params.userExtraData.parentEmail
        ) {
            dataToSend.append("is_youth", "1");
            dataToSend.append(
                "parent_first_name",
                params.userExtraData.parentFirstName
            );
            dataToSend.append(
                "parent_last_name",
                params.userExtraData.parentLastName
            );
            dataToSend.append("parent_email", params.userExtraData.parentEmail);
        }

        return apiCall(
            "/v2/egroup/addContactToGroup",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.data) {
                    if (
                        params.callback &&
                        _.isFunction(params.callback) &&
                        response.status_code === 200
                    ) {
                        params.callback(8); // go to step 8 after it is successful
                    }

                    return {
                        ...response.data,
                        isError: false,
                    };
                } else {
                    const dataToSend = {
                        ...response,
                        isError: true,
                    };
                    return dataToSend;
                }
            });
    }
);

const egroups_get_egroup_leader_events_campus = createAsyncThunk<
    EventOrientation[],
    { campusName: string; leaderType: string }
>(
    "eGroup/fetchLeaderEventsByCampus",
    async (params: { campusName: string; leaderType: string }) => {
        return apiCall(
            `/v2/event/getEventsByCampus?campus_name=${params.campusName}&record_type=egroup_orientation&leader_type=${params.leaderType}`
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.data) {
                    return formateGroupOrientations(response.data);
                } else {
                    return [];
                }
            });
    }
);

const egroups_singup_egroup_leader_events_campus = createAsyncThunk(
    "eGroup/fetchAddContactLeaderEventsByCampus",
    async (
        params: {
            contactId: string;
            jobId: string;
            shiftId: string;
            callback?: Function;
        },
        { getState, dispatch, rejectWithValue }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return rejectWithValue(null);
            }
        }

        const dataToSend = new FormData();
        dataToSend.append("contact_id", params.contactId);
        dataToSend.append("event_id", params.shiftId);

        return apiCall(
            "/v2/event/addContactToEvent",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.data) {
                    if (params.callback && _.isFunction(params.callback)) {
                        params.callback();
                    }

                    return response.data;
                } else {
                    return {};
                }
            });
    }
);

const egroups_get_leader_egroups = createAsyncThunk(
    "eGroup/fetchGetLeadereGroups",
    async (
        params: { contactId: string; callback?: Function },
        { getState, dispatch, rejectWithValue }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return rejectWithValue(null);
            }
        }

        return apiCall(
            `/v2/egroup/geteGroupLeaderGroups/${params.contactId}?hide_youth=true`,
            "GET",
            undefined,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(
                        response && response.data && response.data.egroups
                            ? response.data.egroups
                            : []
                    );
                }

                if (response && response.data && response.data.egroups) {
                    return response.data.egroups;
                } else {
                    return [];
                }
            });
    }
);

const egroups_edit_leader_egroup = createAsyncThunk(
    "eGroup/fetchEditLeaderGroup",
    async (
        params: {
            egroupId: string;
            name: string;
            description: string;
            startDate: string;
            type: string;
            study: string;
            successCallback?: Function;
        },
        { getState, dispatch }
    ) => {
        const dataToSend = new FormData();
        dataToSend.append("egroup_id", params.egroupId);
        dataToSend.append("name", params.name);
        dataToSend.append("description", params.description);
        dataToSend.append("start_date", params.startDate);
        dataToSend.append("type", params.type);
        dataToSend.append("study", params.study);

        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (
                    params.successCallback &&
                    _.isFunction(params.successCallback)
                ) {
                    params.successCallback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        type answerObj = {
            egroup_id: string;
            name: string;
            description: string;
            start_date: string;
            type: string;
            study: string;
            response: ObjectLiteral | null;
            success: boolean;
        };

        const dataToAnswer: answerObj = {
            egroup_id: params.egroupId,
            name: params.name,
            description: params.description,
            start_date: params.startDate,
            type: params.type,
            study: params.study,
            success: false,
            response: null,
        };

        return apiCall(
            "/v2/egroup/changeEgroupStudy/",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                dataToAnswer.response = response;

                if (response && response.status_code === 200) {
                    dataToAnswer.success = true;
                }

                return dataToAnswer;
            });
    }
);

const egroups_edit_leader_contact = createAsyncThunk(
    "eGroup/fetchEditLeaderContact",
    async (
        params: {
            contactId: string;
            email?: string;
            dialingCode?: number;
            phone?: string;
        },
        { getState, dispatch }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        const dataToSend = new FormData();

        dataToSend.append("contact_id", params.contactId);

        if (params.email) {
            dataToSend.append("email", params.email);
        }

        if (params.phone) {
            dataToSend.append("mobile_number", params.phone);
            dataToSend.append(
                "mobile_dialing_code",
                params.dialingCode ? params.dialingCode.toString() : ""
            );
        }

        return apiCall(
            "/v2/contact/updateLeaderContactInfo/",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                return response;
            });
    }
);

const egroups_get_types = createAsyncThunk(
    "eGroup/fetchGeteGroupType",
    async () => {
        return apiCall("/v2/egroup/egroupTypes", "GET")
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.data) {
                    return response.data.map(formatEgroupType);
                } else {
                    return [];
                }
            });
    }
);

const egroups_get_collections = createAsyncThunk<
    {
        data: any[];
        successTimestamp: number;
    },
    number
>("eGroup/fetchGeteGroupCollections", async (quantity?: number) => {
    return apiCall(
        `/v2/egroup/getEgroupCollections${
            quantity ? "?quantity=" + quantity : ""
        }`,
        "GET"
    )
        .then(parseAPIResponse, (error) => {
            Sentry.captureMessage(error);
        })
        .then((response) => {
            let dataToSend = [];
            if (response && response.data) {
                dataToSend = response.data.map(formatCollection);
            }

            return {
                data: dataToSend,
                successTimestamp: Date.now(),
            };
        });
});

//Reducer

const eGroupStore = createSlice({
    name: "eGroup",
    initialState: defaultState,
    reducers: {
        main_loading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        cleareGroupRequest(state, action: PayloadAction<string>) {
            const indexRequest = _.findIndex(state.eGroupRequests, {
                requestId: action.payload,
            });

            if (indexRequest > -1) {
                state.eGroupRequests = state.eGroupRequests.splice(
                    indexRequest,
                    1
                );
            }
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(egroups_get_all_egroups.fulfilled, (state, action) => {
                const indexRequest = _.findIndex(state.eGroupRequests, {
                    requestId: action.payload.requestId,
                });

                if (indexRequest > -1) {
                    state.eGroupRequests[indexRequest] = action.payload;
                } else {
                    state.eGroupRequests.push(action.payload);
                }

                state.isLoading = false;
                state.isLoadingGroups = false;
            })
            .addCase(egroups_get_all_egroups.pending, (state) => {
                state.isLoadingGroups = true;
                state.eGroupLeaderEditSuccess = false;
                state.eGroupLeaderEditResponse = null;
            })
            .addCase(egroupsGetEgroups.fulfilled, (state, action) => {
                const indexRequest = _.findIndex(state.eGroupRequests, {
                    requestId: action.payload.requestId,
                });

                if (indexRequest > -1) {
                    state.eGroupRequests[indexRequest] = action.payload;
                } else {
                    state.eGroupRequests.push(action.payload);
                }

                if (
                    action.payload["egroupsFilterList"] &&
                    _.isArray(action.payload["egroupsFilterList"])
                ) {
                    state.eGroupFilters = action.payload["egroupsFilterList"];
                }

                if (
                    action.payload["egroupsDefaultFilterList"] &&
                    _.isArray(action.payload["egroupsDefaultFilterList"])
                ) {
                    state.eGroupDefaultFilters =
                        action.payload["egroupsDefaultFilterList"];
                }

                const indexRequestLoading = _.findIndex(
                    state.isLoadingEgroups,
                    {
                        requestId: action.meta.arg.requestId,
                    }
                );

                if (indexRequestLoading > -1) {
                    state.isLoadingEgroups[indexRequestLoading] = {
                        requestId: action.meta.arg.requestId,
                        isLoading: false,
                    };
                }
            })
            .addCase(egroupsGetEgroupsFilters.pending, (state) => {
                state.isLoadingFilters = true;
            })
            .addCase(egroupsGetEgroupsFilters.fulfilled, (state, action) => {
                state.eGroupFilters = action.payload.egroupsFilterList;
                state.eGroupDefaultFilters =
                    action.payload.egroupsDefaultFilterList;
                state.isLoadingFilters = false;
            })
            .addCase(egroupsGetEgroups.pending, (state, action) => {
                const indexRequestLoading = _.findIndex(
                    state.isLoadingEgroups,
                    {
                        requestId: action.meta.arg.requestId,
                    }
                );
                const indexRequestData = _.findIndex(state.eGroupRequests, {
                    requestId: action.meta.arg.requestId,
                });

                if (indexRequestLoading > -1) {
                    state.isLoadingEgroups[indexRequestLoading] = {
                        requestId: action.meta.arg.requestId,
                        isLoading: true,
                    };
                } else {
                    state.isLoadingEgroups.push({
                        requestId: action.meta.arg.requestId,
                        isLoading: true,
                    });
                }

                if (indexRequestData > -1) {
                    state.eGroupRequests = state.eGroupRequests.slice(
                        indexRequestData,
                        1
                    );
                }

                state.isLoading = true;
            })
            .addCase(
                egroups_get_all_egroup_demographics.fulfilled,
                (state, action) => {
                    state.egroupsDemographicList = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(
                egroups_get_all_egroup_languages.fulfilled,
                (state, action) => {
                    state.eGroupLanguages = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(egroups_get_egroup.fulfilled, (state, action) => {
                state.eGroupData = action.payload;
                state.eGroupError = null;
                state.isLoading = false;
            })
            .addCase(egroups_get_egroup.rejected, (state, action) => {
                const data = action.payload as { error: ObjectLiteral };

                state.eGroupError = data?.error;
                state.isLoading = false;
            })
            .addCase(egroups_join_egroup.fulfilled, (state, action) => {
                const data = action.payload;

                if (data.isError) {
                    state.joineGroupResponseError = data;
                }

                state.isLoading = false;
            })
            .addCase(egroups_join_egroup.pending, (state) => {
                state.joineGroupResponseError = null;
            })
            .addCase(
                egroups_get_egroup_leader_events_campus.fulfilled,
                (state, action) => {
                    state.leaderEventsCampusData = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(
                egroups_singup_egroup_leader_events_campus.fulfilled,
                (state, action) => {
                    state.eGroupLeaderEventRegisterData = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(egroups_get_leader_egroups.fulfilled, (state, action) => {
                state.eGroupLeaderEgroups = action.payload;
                state.isLoading = false;
            })
            .addCase(egroups_edit_leader_egroup.fulfilled, (state, action) => {
                if (action.payload) {
                    state.eGroupLeaderEditSuccess = action.payload.success;
                    state.eGroupLeaderEditResponse = action.payload.response;
                }

                state.isLoading = false;
            })
            .addCase(egroups_edit_leader_contact.fulfilled, (state, action) => {
                state.eGroupLeaderContactEditSuccess =
                    action.payload &&
                    action.payload.status_code &&
                    action.payload.status_code === 200
                        ? true
                        : false;
                state.eGroupLeaderContactEditResponse = action.payload;

                state.isLoading = false;
            })
            .addCase(egroupsGetHomeByUrl.fulfilled, (state, action) => {
                state.eGroupHomeData = action.payload;
                state.isLoading = false;
            })
            .addCase(egroups_get_types.fulfilled, (state, action) => {
                state.eGroupTypes = action.payload;
                state.isLoading = false;
            })

            .addCase(egroups_get_collections.fulfilled, (state, action) => {
                state.egroupCollections = action.payload.data;
                state.egroupCollectionsSuccessTimestamp =
                    action.payload.successTimestamp;

                state.isLoadingCollections = false;
            })

            .addCase(egroups_edit_leader_contact.pending, (state) => {
                state.eGroupLeaderContactEditSuccess = false;
                state.eGroupLeaderContactEditResponse = null;

                state.isLoading = false;
            })

            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("pending") &&
                        action.type.indexOf("eGroup") > -1
                    );
                },
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("rejected") &&
                        action.type.indexOf("eGroup") > -1
                    );
                },
                (state) => {
                    state.isLoading = false;
                    state.isLoadingEgroups = [];
                }
            ),
});

export const actions = {
    egroups_get_all_egroups,
    egroupsGetEgroups,
    egroups_get_all_egroup_demographics,
    egroups_get_all_egroup_languages,
    egroups_get_egroup,
    egroups_join_egroup,
    egroups_get_egroup_leader_events_campus,
    egroups_singup_egroup_leader_events_campus,
    egroups_get_leader_egroups,
    egroups_edit_leader_egroup,
    egroups_edit_leader_contact,
    egroupsGetHomeByUrl,
    egroups_get_types,
    egroups_get_collections,
    egroupsGetEgroupsFilters,
    ...eGroupStore.actions,
};

export default eGroupStore;
