import { AnyAction } from "redux";

import { BaptismsState } from "./types";
import { parseAPIResponse, apiCall } from "../../services/api";
import _ from "lodash";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tokenErrorText } from "../../constants";
import { ReducerType } from "../rootReducer";
import * as Sentry from "@sentry/nextjs";
import MainActions from "../main";
/**
 *
 * This file contains the actions for the Main (actions used across the site) - reference: https://redux.js.org/basics/actions
 * The actions array contains the list of available actions to be included in the global props
 * The moduleName defines the base name of the actions so it doesnt get confused across components
 *
 * baptisms_loading => baptisms_loading
 *
 * This site uses Thunk to manage async actions so reference https://github.com/reduxjs/redux-thunk for the
 * required format, specif types and usage.
 *
 */

const defaultState: BaptismsState = {
    isLoading: false,
    baptismRegisterData: null,
};

const baptisms_get_campus_baptism = createAsyncThunk(
    "baptisms/fetchCampusBaptism",
    async (params: { campusName: string; type: string }) => {
        return apiCall(
            `/v2/event/getEventsByCampus?campus_name=${params.campusName}&record_type=baptism&baptism_type=${params.type}`
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })

            .then((data) => {
                if (data && data.data) {
                    return data.data;
                } else {
                    return {};
                }
            });
    }
);

const baptisms_register_user_baptism = createAsyncThunk(
    "baptisms/fetchRegisterUserBaptism",
    async (
        params: {
            contactId: string;
            jobId: string;
            shiftId: string;
            callback?: Function;
        },
        { getState, dispatch }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        const dataToSend = new FormData();

        dataToSend.append("contact_id", params.contactId);
        dataToSend.append("event_id", params.shiftId);

        return apiCall(
            "/v2/event/addContactToEvent",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })

            .then((data) => {
                if (data && data.data) {
                    if (params.callback && _.isFunction(params.callback)) {
                        params.callback();
                    }

                    return data.data;
                } else {
                    return {};
                }
            });
    }
);

const baptismStore = createSlice({
    name: "baptism",
    initialState: defaultState,
    reducers: {
        main_loading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(baptisms_get_campus_baptism.fulfilled, (state, action) => {
                state.baptismRegisterData = action.payload;

                state.isLoading = false;
            })

            .addCase(
                baptisms_register_user_baptism.fulfilled,
                (state, action) => {
                    state.baptismRegisterData = action.payload;

                    state.isLoading = false;
                }
            )

            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("pending") &&
                        action.type.indexOf("baptisms") > -1
                    );
                },
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("rejected") &&
                        action.type.indexOf("baptisms") > -1
                    );
                },
                (state) => {
                    state.isLoading = false;
                }
            ),
});

export const actions = {
    baptisms_get_campus_baptism,
    baptisms_register_user_baptism,
    ...baptismStore.actions,
};

export default baptismStore;
