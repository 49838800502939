// import { spring } from "react-router-transition";
import moment, { Moment } from "moment";
import * as Sentry from "@sentry/nextjs";

// Generic Object type
export interface ObjectLiteral {
    [key: string]: any;
}

// function bounce(val: number) {
//     return spring(val, {
//         stiffness: 330,
//         damping: 22,
//     });
// }
// The route Transitions
export function slideTransition() {
    // start in a transparent, upscaled state
    return {
        atEnter: {
            opacity: 0,
            scale: 1.1,
            translateX: 100,
            flexBasis: 0,
            flexGrow: 1,
            flexShrink: 0,
        },
        // leave in a transparent, downscaled state
        atLeave: {
            opacity: 0,
            scale: 0.8,
            translateX: -100,
            flexBasis: 0,
            flexGrow: 0,
            flexShrink: 1,
        },
        // and rest at an opaque, normally-scaled state
        atActive: {
            // opacity: bounce(1),
            // scale: bounce(1),
            translateX: 0,
            flexBasis: 100,
            flexGrow: 1,
            flexShrink: 0,
        },
    };
}

// main component props Types
export type MainAppProps = {
    history: any;
    router?: any;
};

// default route props Types
export type RouteProps = {
    exact?: boolean;
    path?: string | string[];
    component: any;
    defaultProps: ObjectLiteral;
    location?: any;
    containerClassFunction?: Function;
    setFooterVisibility?: Function;
    setHeaderVisibility?: Function;
    containerClass?: string;
};

// required for the animation between screens
export function mapStyles(styles: ObjectLiteral) {
    return {
        opacity: styles.opacity,
        // transform: `scale(${styles.scale}) translateX(${styles.translateX}%)`,
        width: `${styles.flexBasis}%`,

        display: "inline-block",
        left: `${styles.flexBasis}%`,
    };
}

// required for the function to convert the eGroup leaders names to gradient (when the photo isnt available)
export function alphabetPosition(text: string) {
    const result: Array<number> = [];
    for (let i = 0; i < text.length; i++) {
        const code = text.toUpperCase().charCodeAt(i);
        if (code > 64 && code < 91) result.push(code - 64);
    }

    return result;
}

// converts the initial of a leader to a gradient number to generate random
export function getRandomNumberBasedLeadersNames(
    leaderInitials: string,
    minNumber: number,
    maxNumber: number
): number {
    const leaderInitialsToNumnbers = alphabetPosition(leaderInitials);
    let gradientNumber = Math.floor(
        (leaderInitialsToNumnbers[0] + leaderInitialsToNumnbers[1]) / maxNumber
    );

    if (gradientNumber < minNumber) {
        gradientNumber = minNumber;
    } else if (gradientNumber > maxNumber) {
        gradientNumber = maxNumber;
    }

    return gradientNumber;
}

export function logError(
    error: string,
    level: Sentry.Severity,
    extraContext: ObjectLiteral = {}
) {
    Sentry.withScope((scope) => {
        if (extraContext) {
            Object.keys(extraContext).forEach((key) => {
                scope.setExtra(key, extraContext[key]);
            });
        }
        Sentry.captureMessage(error, level);
    });
}

export const minYearsToCheckForParentInfo = parseInt(
    process.env.NEXT_PUBLIC_CONTACT_AGE_TO_SHOW_PARENT_INFO || "17"
);

export function parentDataRequiredToRegister(birthdate: Moment): boolean {
    const parentCheckEnabled = parseInt(
        process.env.NEXT_PUBLIC_ENABLE_CHECK_CONTACT_AGE_TO_SHOW_PARENT_INFO ||
            "0"
    );

    if (parentCheckEnabled === 0) {
        return false;
    }

    const today = moment();
    const age = today.diff(birthdate, "years");

    return age < minYearsToCheckForParentInfo;
}

export const minYearsToRegister = parseInt(
    process.env.NEXT_PUBLIC_MIN_CONTACT_AGE || "10"
);

export const tokenErrorText = "Request has expired. Please try again.";

export const shimmer = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#e9e9ec" offset="20%" />
      <stop stop-color="#dedee3" offset="50%" />
      <stop stop-color="#e9e9ec" offset="80%" />
    </linearGradient>
  </defs>
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="2s" repeatCount="indefinite"  />
</svg>`;

export const toBase64 = (str) =>
    typeof window === "undefined"
        ? Buffer.from(str).toString("base64")
        : window.btoa(str);

export const customPhoneMasks = { jm: "...-...." };
