import React, { Fragment } from "react";
import dynamic from "next/dynamic";
import * as Sentry from "@sentry/nextjs";
import type { Header as HeaderType } from "@digital-ninjas/elevation-church-elements";

type HeaderPropType = JSX.LibraryManagedAttributes<
    typeof HeaderType,
    React.ComponentProps<typeof HeaderType>
>;
const HeaderComponent = dynamic<HeaderPropType>(
    () =>
        import("@digital-ninjas/elevation-church-elements").then(
            (mod) => mod.Header
        ),

    {
        // eslint-disable-next-line react/display-name
        loading: () => <div style={{ minHeight: 84.94 }}></div>,
        ssr: false,
    }
);

const FooterComponent = dynamic(
    (() =>
        import("@digital-ninjas/elevation-church-elements").then(
            (mod) => mod.Footer
        )) as any,
    { ssr: false }
);

type Props = {
    containerClassName?: string;
    showHeader?: boolean;
    showFooter?: boolean;
};

// MainLayout Component
/**
 * This component is used to render the general layout of the views
 */

class MainLayout extends React.Component<Props> {
    // the values the props will get when they are not present
    static defaultProps = {
        showHeader: true,
        showFooter: true,
        addBottomPadding: false,
    };

    componentDidMount() {
        const a = document.querySelector('a[class="brand site-logo"]');
        if (a) {
            a.setAttribute("href", "/");
        }
    }

    componentDidCatch(error: any, errorInfo: any) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
        });
    }

    // Toogle Menu Function
    /**
     * toggleMenu() this is the callback after a user clicks menu icon on the header. This chances the class of
     * the body to show the correct styling
     */
    toggleMenu = (isOpen: boolean) => {
        if (isOpen) {
            document.body.classList.add("menu-opened");
        } else {
            document.body.classList.remove("menu-opened");
        }
    };

    render() {
        return (
            <Fragment>
                {this.props.showHeader ? (
                    <HeaderComponent
                        toggleMenuCallback={this.toggleMenu}
                        translationApiKey={
                            process.env.NEXT_PUBLIC_WEGLOT_API_KEY
                        }
                    />
                ) : null}
                <div
                    className="wrap flex-grow-noshrink max-w-100vw"
                    role="document"
                >
                    <div
                        className={`content ${
                            this.props.containerClassName || ""
                        }`}
                    >
                        <main className="main">{this.props.children}</main>
                    </div>
                </div>
                {this.props.showFooter ? (
                    <div className="b-t-spacer-xs">
                        <FooterComponent />
                    </div>
                ) : null}
            </Fragment>
        );
    }
}

export default MainLayout;
