import "../src/styles/index.scss";

import type { AppProps, NextWebVitalsMetric } from "next/app";
import TagManager from "react-gtm-module";
import { Offline } from "react-detect-offline";
import Head from "next/head";

import { wrapper } from "../src/js/store/configureStore";
import { NextPage } from "next";
import { ReactElement, ReactNode } from "react";
import MainLayout from "@/components/layouts/Main";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { CookiesProvider } from "react-cookie";
import dynamic from "next/dynamic";

const tagManagerArgs = {
    gtmId: `${process.env.NEXT_PUBLIC_GTM_ID}`,
};

// This file is the main App entry point and it has the configuration for the default routes
if (process.browser) {
    TagManager.initialize(tagManagerArgs);
}

type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode;
    showHeader?: boolean;
    showFooter?: boolean;
    wrapperClassName?: string;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};

const client = new ApolloClient({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ROUTE || "",
    cache: new InMemoryCache(),
});

function ConnecionCenterApp({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout =
        Component.getLayout ??
        ((page) => {
            // const DynamicLayout = dynamic(
            //     () => import("@/components/layouts/Main")
            // );

            return (
                <MainLayout
                    showHeader={Component.showHeader ?? true}
                    showFooter={Component.showFooter ?? true}
                    containerClassName={Component.wrapperClassName ?? ""}
                >
                    {page}
                </MainLayout>
            );
        });

    return getLayout(
        <>
            <Head>
                <meta
                    name="title"
                    key="meta-title"
                    content="Elevation Church Connection Center"
                />
                <meta
                    name="description"
                    content="Whether you're new or already part of the family, we'd love to help you get connected. From eGroups to volunteering to baptism, find your next step today!"
                    key="meta-description"
                />

                <meta key="meta-og-type" property="og:type" content="website" />
                <meta
                    property="og:title"
                    key="meta-og-title"
                    content="Elevation Church Connection Center"
                />
                <meta
                    property="og:description"
                    key="meta-og-description"
                    content="Whether you're new or already part of the family, we'd love to help you get connected. From eGroups to volunteering to baptism, find your next step today!"
                />
                <meta
                    property="og:image"
                    key="meta-og-image"
                    content="https://elevationchurch.org/app/uploads/2021/01/ec-connectioncenter-og-2.jpg"
                />

                <meta
                    key="meta-twitter-card"
                    property="twitter:card"
                    content="summary_large_image"
                />
                <meta
                    property="twitter:title"
                    key="meta-twitter-title"
                    content="Elevation Church Connection Center"
                />
                <meta
                    property="twitter:description"
                    key="meta-twitter-description"
                    content="Whether you're new or already part of the family, we'd love to help you get connected. From eGroups to volunteering to baptism, find your next step today!"
                />
                <meta
                    property="twitter:image"
                    key="meta-twitter-image"
                    content="https://elevationchurch.org/app/uploads/2021/01/ec-connectioncenter-og-2.jpg"
                />
            </Head>
            <ApolloProvider client={client}>
                <CookiesProvider>
                    <Component {...pageProps} />
                </CookiesProvider>
            </ApolloProvider>
            {process.env.NEXT_PUBLIC_ENABLE_OFFLINE_ALERT === "true" ? (
                <Offline
                    polling={{
                        url: "https://cc-api.elevationchurch.ninja/v1/ping",
                    }}
                >
                    <div className="fix-pos uptop full-width full-height generic-overlay way-forward">
                        <div className="ui abs-pos absolute-center form p-y-spacer-lg p-x-spacer-lg ec-border-radius ec-box-shadow ec-background-white phone-number-form text-center">
                            <span className="sec-font bold">
                                No Internet Connection
                            </span>{" "}
                            <br />
                            Make sure that Wi-FI or mobile data is turned on.
                        </div>
                    </div>
                </Offline>
            ) : null}
        </>
    );
}

export default wrapper.withRedux(ConnecionCenterApp);
