import { ObjectLiteral } from "../constants";
import { logError } from "../constants";
import * as Sentry from "@sentry/nextjs";
import _ from "lodash";

// Default parse API response
export function parseAPIResponse(response: any) {
    return response.text().then(function (text: string) {
        try {
            if (text && !_.isEmpty(text)) {
                return JSON.parse(text);
            } else if (_.isEmpty(text)) {
                return null;
            }
            const error = { error: "Empty response" };
            throw error;
        } catch (e) {
            logError(e.message, Sentry.Severity.Critical, {
                response,
                error: e,
            });

            return {
                status_code: 400,
                message: "Error parsing JSON",
            };
        }
    });
}

// default headers for all the API calls
export const defaultHeaders = {
    Accept: "application/json",
};

// Method to generate the fetching data call to the API
export async function apiCall(
    route: string,
    method: string = "GET",
    body?: FormData,
    extraHeaders?: ObjectLiteral,
    token?: string,
    apiDomain: string = `${process.env.NEXT_PUBLIC_API_DOMAIN}`
) {
    const headers: ObjectLiteral = {
        ...defaultHeaders,
        ...extraHeaders,
    };

    try {
        if (token) {
            headers["Authorization"] = `Bearer ${token}`;
        }

        return fetch(`${apiDomain}${route}`, {
            method,
            body: body || null,
            headers,
        });
    } catch (e) {
        logError(e.message, Sentry.Severity.Debug, {
            error: e,
            url: `${apiDomain}${route}`,
            body: body || null,
            headers,
        });
    }
}
