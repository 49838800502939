import {
    CampusLocation,
    ConfirmationType,
    ConfirmationTypeData,
    MainState,
    User,
} from "./types";
import {
    AnyAction,
    createAction,
    createAsyncThunk,
    createSlice,
    PayloadAction,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/nextjs";
import { apiCall, parseAPIResponse } from "../../services/api";
import { registrationType } from "../../components/propTypes";
import { ObjectLiteral, tokenErrorText } from "../../constants";
import { formatLocations } from "./formatter";
import _ from "lodash";
import { ReducerType } from "../rootReducer";
import MainActions from "../main";
import { HYDRATE } from "next-redux-wrapper";

//This file contains the reducer for the Main (actions used across the site) Redux actions. Reference - https://redux.js.org/basics/reducers

//Default state of the app on Main
const defaultState: MainState = {
    isLoading: false,
    campuses: [],
    tshirtSizes: [],
    countries: [],
    states: [],
    confirmationCodeData: {
        status_code: null,
    },
    validateConfirmationCodeData: {
        status_code: null,
    },
    userData: null,
    searchContactData: {
        status_code: null,
    },
    searchContactError: "",
    campusesLocationList: [],
    isLoadingCampusesLocations: false,
    token_error: "",
    contactEditSuccess: false,
    contactEditResponse: null,
    desktopMinSize: 992,
    phoneMaxSize: 650,
};

const main_get_all_campuses = createAsyncThunk(
    "main/fetchAllCampuses",
    async () => {
        return apiCall(
            "/v1/common/campus",
            "GET",
            undefined,
            undefined,
            undefined,
            `${process.env.NEXT_PUBLIC_NEW_API_DOMAIN}`
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((data) => {
                if (data && data.length) {
                    return data.filter((campus) => {
                        return campus.active;
                    });
                } else {
                    return [];
                }
            });
    }
);

const main_get_country_list = createAsyncThunk(
    "main/fetchCountryList",
    async () => {
        return apiCall("/v2/commonMethods/getCountriesList")
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((data) => {
                if (data && data.data) {
                    return data.data;
                } else {
                    return [];
                }
            });
    }
);

const main_get_tshirt_size_list = createAsyncThunk(
    "main/fetchTshirtSizeList",
    async () => {
        return apiCall("/v2/contact/getTshirtSizeList")
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((data) => {
                if (data && data.data) {
                    return data.data;
                } else {
                    return [];
                }
            });
    }
);

const main_get_states_list = createAsyncThunk(
    "main/fetchStateList",
    async () => {
        return apiCall("/v2/commonMethods/getStatesList")
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((data) => {
                if (data && data.data) {
                    return data.data;
                } else {
                    return [];
                }
            });
    }
);

const main_send_confirmation_code = createAsyncThunk(
    "main/sendConfirmationCode",
    async (param: {
        dataToSend: { sms?: boolean; number?: string; email?: string };
        origin: registrationType;
        extraData?: ObjectLiteral;
    }) => {
        const dataToSend = new FormData();
        const data = param.dataToSend;
        const extraData = param.extraData;
        const origin = param.origin;

        if (data.sms && data.number) {
            const phoneNumber = data.number.replace(/\D/g, "");

            dataToSend.append("type", "sms");

            if (extraData && extraData.dialCode) {
                dataToSend.append("mobile_dialing_code", extraData.dialCode);
                dataToSend.append(
                    "to_number",
                    phoneNumber.replace(extraData.dialCode, "")
                );
            }

            dataToSend.append("from_whatsapp", "0");
        } else if (data.email) {
            dataToSend.append("type", "email");
            dataToSend.append("email", data.email);
        }

        dataToSend.append("origin", origin.toLowerCase());

        return apiCall(
            "/v2/validationCode/sendValidationCode",
            "POST",
            dataToSend
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return [];
                }
            });
    }
);

const main_validate_confirmation_code = createAsyncThunk(
    "main/validateConfirmationCode",
    async (params: {
        confirmationCode: string;
        confirmation_type: ConfirmationType;
        confirmation_type_data: ConfirmationTypeData;
    }) => {
        const dataToSend = new FormData();
        dataToSend.append("otp", params.confirmation_type);
        if (params.confirmation_type === "email") {
            dataToSend.append("email", params.confirmation_type_data.data);
        } else {
            if (
                params.confirmation_type_data.extraInfo &&
                params.confirmation_type_data.extraInfo.mobile_dialing_code
            ) {
                dataToSend.append(
                    "mobile_dialing_code",
                    params.confirmation_type_data.extraInfo.mobile_dialing_code
                );
            }

            dataToSend.append(
                "phone_number",
                params.confirmation_type_data.data
            );
        }

        dataToSend.append("code", params.confirmationCode);

        return apiCall("/auth/login", "POST", dataToSend)
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response) {
                    let status_code = response.status_code;

                    if (!response.status_code) {
                        status_code = 401;
                    }

                    return {
                        ...response,
                        status_code,
                    };
                } else {
                    return {};
                }
            });
    }
);

const main_search_contact = createAsyncThunk(
    "main/searchContact",
    async (
        params: {
            dataToSearch: ObjectLiteral;
            searchOrCreate?: boolean;
            callback?: Function;
        },
        { rejectWithValue, getState, dispatch }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                if (params.callback && _.isFunction(params.callback)) {
                    params.callback(1);
                }

                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        const dataToSend = new FormData();

        Object.keys(params.dataToSearch).forEach((key: string) => {
            dataToSend.append(key, params.dataToSearch[key]);
        });

        dataToSend.append(
            "search_and_create",
            params.searchOrCreate ? "1" : "0"
        );

        return apiCall(
            "/v2/contact/getContact",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((returnData) => {
                if (returnData && returnData.status !== "CONFLICT") {
                    return {
                        returnData,
                        searchOrCreate: params.searchOrCreate,
                        statusCode: returnData.status_code,
                    };
                } else if (returnData && returnData.status === "CONFLICT") {
                    return rejectWithValue({
                        returnData,
                        searchOrCreate: params.searchOrCreate,
                        statusCode: returnData.status_code,
                    });
                } else {
                    return {};
                }
            });
    }
);

const main_get_all_campuses_locations = createAsyncThunk<CampusLocation[]>(
    "main/fetchLocationsList",
    async () => {
        return apiCall("/v2/wordpress/ec/getLocationsList")
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                if (response && response.data) {
                    return formatLocations(response.data);
                } else {
                    return [];
                }
            });
    }
);

const main_edit_contact = createAsyncThunk(
    "main/fetchEditContact",
    async (
        params: { contactId: string; tshirtSize: string },
        { getState, dispatch }
    ) => {
        const { main } = getState() as ReducerType;
        let token = undefined;

        if (
            main.userData &&
            main.userData.token &&
            main.userData.token.access_token
        ) {
            token = main.userData.token.access_token;

            if (parseInt(main.userData.token.expires_at) < Date.now()) {
                dispatch(
                    MainActions.actions.main_set_token_error(tokenErrorText)
                );
                return false;
            }
        }

        const dataToSend = new FormData();
        dataToSend.append("contact_id", params.contactId);

        if (params.tshirtSize) {
            dataToSend.append("tshirt_size", params.tshirtSize);
        }
        return apiCall(
            "/v2/contact/updateContactInfo/",
            "POST",
            dataToSend,
            undefined,
            token
        )
            .then(parseAPIResponse, (error) => {
                Sentry.captureMessage(error);
            })
            .then((response) => {
                return response;
            });
    }
);
const hydrateAction = createAction<ObjectLiteral>(HYDRATE);
const mainStore = createSlice({
    name: "main",
    initialState: defaultState,
    reducers: {
        main_loading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
            state.token_error = "";
        },
        main_set_user_data(state, action: PayloadAction<User>) {
            if (
                state.userData &&
                state.userData.token &&
                !action.payload.token
            ) {
                return {
                    ...state,
                    userData: {
                        ...action.payload,
                        token: state.userData.token,
                    },
                };
            }

            state.userData = action.payload;
        },
        main_update_contact_data(state, action: PayloadAction<User>) {
            state.userData = action.payload;
        },
        main_set_token_error(state, action: PayloadAction<string>) {
            state.token_error = action.payload;
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(main_get_all_campuses.fulfilled, (state, action) => {
                state.campuses = action.payload;
                state.isLoading = false;
            })
            .addCase(main_get_tshirt_size_list.fulfilled, (state, action) => {
                state.tshirtSizes = action.payload;
                state.isLoading = false;
            })
            .addCase(main_get_country_list.fulfilled, (state, action) => {
                state.countries = action.payload;
                state.isLoading = false;
            })
            .addCase(main_get_states_list.fulfilled, (state, action) => {
                state.states = action.payload;
                state.isLoading = false;
            })
            .addCase(main_send_confirmation_code.pending, (state) => {
                state.confirmationCodeData = { success: null };
            })
            .addCase(main_send_confirmation_code.fulfilled, (state, action) => {
                state.confirmationCodeData = action.payload;
                state.isLoading = false;
            })
            .addCase(main_validate_confirmation_code.pending, (state) => {
                state.validateConfirmationCodeData = { status_code: null };
            })
            .addCase(
                main_validate_confirmation_code.fulfilled,
                (state, action) => {
                    state.validateConfirmationCodeData = action.payload;
                    state.userData = action.payload.data;
                    state.isLoading = false;
                }
            )
            .addCase(main_search_contact.pending, (state) => {
                state.searchContactData = {};
                state.searchContactError = "";
            })
            .addCase(main_edit_contact.fulfilled, (state, action) => {
                state.contactEditSuccess =
                    action.payload &&
                    action.payload.status_code &&
                    action.payload.status_code === 200
                        ? true
                        : false;
                state.contactEditResponse = action.payload;
                state.isLoading = false;
            })
            .addCase(main_search_contact.fulfilled, (state, action) => {
                if (action.payload) {
                    if (
                        !action.payload.searchOrCreate &&
                        action.payload.returnData &&
                        _.isArray(action.payload.returnData.data) &&
                        action.payload.returnData.data.length === 1
                    ) {
                        let userData = action.payload.returnData.data[0];

                        if (!action.payload.returnData.data[0].token) {
                            userData = {
                                token: state.userData?.token,
                                ...action.payload.returnData.data[0],
                            };
                        }
                        state.userData = userData;
                    } else if (
                        action.payload.searchOrCreate &&
                        action.payload.returnData.data
                    ) {
                        let userData = action.payload.returnData.data;

                        if (!action.payload.returnData.data.token) {
                            userData = {
                                token: state.userData?.token,
                                ...action.payload.returnData.data,
                            };
                        }

                        state.userData = userData;
                    }
                }

                state.searchContactData = action.payload
                    ? action.payload.returnData
                    : null;
                state.searchContactError = "";
                state.isLoading = false;
            })

            .addCase(main_search_contact.rejected, (state, action) => {
                const data = action.payload as {
                    returnData: ObjectLiteral;
                    searchOrCreate: boolean;
                    statusCode: string;
                };

                if (data) {
                    state.searchContactData = null;
                    if (!data.searchOrCreate && data.returnData) {
                        state.searchContactData = data.returnData.data;
                    } else if (data.searchOrCreate && data.returnData.data) {
                        state.searchContactData = data.returnData.data;
                    }
                }

                state.searchContactError = data.statusCode;
            })

            .addCase(main_edit_contact.pending, (state) => {
                state.contactEditSuccess = false;
                state.contactEditResponse = null;
                state.isLoading = false;
            })

            .addCase(
                main_get_all_campuses_locations.fulfilled,
                (state, action) => {
                    state.campusesLocationList = action.payload;
                    state.isLoading = false;
                    state.isLoadingCampusesLocations = false;
                }
            )
            .addCase(
                main_get_all_campuses_locations.pending,
                (state, action) => {
                    state.isLoadingCampusesLocations = true;
                }
            )
            .addCase(
                hydrateAction,
                (state, action: PayloadAction<ObjectLiteral>) => {
                    return {
                        ...state,
                        ...action.payload.subject,
                    };
                }
            )
            //generic reducer
            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("pending") &&
                        action.type.indexOf("main") > -1
                    );
                },
                (state) => {
                    state.isLoading = true;
                }
            )
            .addMatcher(
                (action: AnyAction): action is PayloadAction => {
                    return (
                        action.type.endsWith("rejected") &&
                        action.type.indexOf("main") > -1
                    );
                },
                (state) => {
                    state.isLoading = false;
                }
            ),
});

export const actions = {
    main_get_all_campuses,
    main_get_country_list,
    main_get_states_list,
    main_send_confirmation_code,
    main_validate_confirmation_code,
    main_search_contact,
    main_get_all_campuses_locations,
    main_edit_contact,
    main_get_tshirt_size_list,
    ...mainStore.actions,
};
export default mainStore;
