import { errorResponse, EventOrientation } from "../../globalTypes";
import { ObjectLiteral } from "../../constants";

export const moduleName = "egroups"; // This module name is used across the app when referencing to the Redux actions and reducer

// Types for the Redux state
export interface EgroupsState {
    isLoading: boolean;
    isLoadingGroups: boolean;
    isLoadingCampuses: boolean;
    egroups: eGroup[];
    egroupsFormatted: eGroup[];
    eGroupTypes: eGroupType[];
    eGroupData: eGroup | null;
    eGroupRequests: eGroupRequest[];
    egroupsDemographicList: Demographic[];
    leaderEventsCampusData: EventOrientation[];
    eGroupLeaderEventRegisterData: ObjectLiteral | null;
    eGroupError: ObjectLiteral | null;
    eGroupLeaderEgroups: eGroup[];
    eGroupLeaderEditSuccess: boolean;
    eGroupLeaderEditResponse: ObjectLiteral | null;
    eGroupLeaderContactEditSuccess: boolean;
    eGroupLeaderContactEditResponse: ObjectLiteral | null;
    eGroupHomeData: eGroupHome | undefined;
    isLoadingEgroups: geteGroupLoading[];
    egroupCollections: eGroupCollection[];
    isLoadingCollections: boolean;
    egroupCollectionsSuccessTimestamp: number;
    joineGroupResponseError: errorResponse | null;
    eGroupLanguages: Language[];
    isLoadingFilters: boolean;
    eGroupFilters: eGroupFilter[];
    eGroupDefaultFilters: eGroupFilter[];
}

export interface eGroupRequest {
    requestId: string;
    egroupsList: eGroup[];
    successTimestamp: number;
    egroupsFilterList?: eGroupFilter[];
    egroupsDefaultFilterList?: eGroupFilter[];
    count: number;
}

export interface processedEGroupFilterContent extends eGroupFilter {
    slugName: string;
    realFieldOrder: string[];
}

export interface eGroupPagination {
    base: string;
    current_page: number;
    last_page: number;
    next: string;
    path: string;
    per_page: number;
    prev: string;
    total: number;
}

interface geteGroupLoading {
    requestId: string;
    isLoading: boolean;
}

export interface eGroupCollection {
    id: number;
    title: string;
    image: string;
    isActive: boolean;
    order: number;
    link: string;
    group?: string;
    layout: {
        showTitle: boolean;
        externalLink?: boolean;
        backgroundColor?: string;
    };
}

export interface eGroupHome {
    description?: string;
    title?: string;
    name?: string;
    url?: string;
    requiredQuery?: ObjectLiteral;
    templateFilters?: eGroupFilter[];
    templateSections?: eGroupSection[];
    templateCollections?: any;
    loadTime: number;
    loadError: boolean;
}

export interface eGroupFilter {
    id: string;
    label: string;
    name: string;
    fields: string[];
    type: string;
    maxSelection?: number;
    displayType: filterDisplayType;
    orderValuesBy?: string;
    orderValuesByDirection?: "asc" | "desc";
    order: number;
    values: egroupFilterValue[];
    sorter?: ObjectLiteral;
    extraParams: ObjectLiteral | null;
    valuesLabel?: valueLabel[];
}

export interface valueLabel {
    label: string;
    value: string;
}

export interface egroupFilterValue {
    value: string;
    label: string;
    count: number;
    children: egroupFilterValue[];
}

enum filterDisplayType {
    list = "list",
    tagSelector = "tagSelector",
    switch = "switch",
    demographicSelector = "demographicSelector",
    campusList = "campusList",
}

export interface eGroupSection {
    id: number;
    title: string;
    description: string;
    isActive: boolean;
    order: number;
    layout: SectionLayout;
    link: string;
    templateEgroupSectionItems: eGroupSectionElement[];
}

export interface eGroupSectionElement {
    id: number;
    title: string;
    description: string;
    image?: string;
    layout: SectionItemLayout;
    query?: SectionElementQuery;
    link?: string;
    isActive: boolean;
    order: number;
}

export interface SectionElementQuery {
    type: "egroup" | "collection";
    content: ObjectLiteral;
}

export interface eGroup {
    id: string;
    name: string;
    campusId: string;
    campusName: string;
    active: boolean;
    slugCampusName: string;
    searchable: true;
    childCare: boolean;
    meetingType: "Virtual" | "In-person" | "In-person & Virtual";
    gender: string;
    egroupStudyValue: string;
    egroupType: string;
    egroupStudy: string;
    locationName: string;
    language: string;
    makeLocationVisible: boolean;
    locationZipCode: string;
    verifiedLatitudeMapanything: number;
    verifiedLongitudeMapanything: number;
    timeZone: string;
    description: string;
    schedule: "Weekly" | "Biweekly";
    dayOfWeek:
        | "Sunday"
        | "Saturday"
        | "Monday"
        | "Tuesday"
        | "Wednesday"
        | "Thursday"
        | "Friday";
    time: string;
    demographic: string;
    lastModifiedDate: string;
    leaders: eGroupLeader[];
    externalRedirectionRequired: boolean;
    externalRedirectionUrl: string;
}

export interface leaderOrientationEvent {
    id: string;
}

export interface eGroupLeader {
    name: string;
    profileImage: string;
}

export interface SectionLayout {
    type: "egroupList" | "swiper" | "collections";
    showTitle: boolean;
    itemsToShowMobile: number;
    itemsToShowDesktop: number;
    showDescription: boolean;
    showButton: boolean;
    buttonText?: string;
    externalLink: boolean;
    maxItemsToLoad?: number;
    collectionsGroup?: string;
}

export interface SectionItemLayout {
    showTitle: boolean;
    itemsToShowMobile?: number;
    itemsToShowDesktop?: number;
    externalLink?: boolean;
}

export interface eGroupType {
    name: string;
    value: any;
    studies: {
        name: string;
        value: string;
    }[];
}

export interface Demographic {
    icon: string;
    name: string;
    salesforce_value: string;
    value: string;
    gender: Gender;
    life_stage: "youth" | "adult";
}

export enum Day {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
}

export enum Gender {
    Female = "Female",
    Male = "Male",
    CoEd = "CoEd",
}

export interface Language {
    name: string;
    value: string;
}
