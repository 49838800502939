import { EventOrientation } from "../../globalTypes";
import _ from "lodash";
import {
    Demographic,
    eGroup,
    eGroupCollection,
    eGroupSection,
    eGroupFilter,
    eGroupSectionElement,
    eGroupType,
    Language,
    SectionItemLayout,
    SectionLayout,
} from "./types";

/// Formatter
export function formatEgroups(eGroups: Array<any>): eGroup[] {
    return eGroups.map((egroup: any) => {
        return formatEgroupData(egroup);
    });
}

export function formatEgroupFilters(
    eGroupsFilters: Array<any>
): eGroupFilter[] {
    return eGroupsFilters.map((egroupFilter: any) => {
        return egroupFilter;
    });
}

export function formatEgroupData(eGroup: any): eGroup {
    return {
        id: eGroup.id,
        name: eGroup.name,
        campusId: eGroup.campusId,
        campusName: eGroup.campusName,
        active: eGroup.active,
        slugCampusName: eGroup.slugCampusName,
        searchable: eGroup.searchable,
        childCare: eGroup.childCare,
        meetingType: eGroup.meetingType,
        gender: eGroup.gender,
        egroupType: eGroup.egroupType,
        egroupStudyValue: eGroup.egroupStudy || "",
        egroupStudy: eGroup.egroupStudy
            ? eGroup.egroupStudy.replace(eGroup.egroupType + " - ", "")
            : "",
        locationName: eGroup.locationName,
        language: eGroup.language || "",
        makeLocationVisible: eGroup.makeLocationVisible,
        locationZipCode: eGroup.locationZipCode,
        verifiedLatitudeMapanything: eGroup.verifiedLatitudeMapanything,
        verifiedLongitudeMapanything: eGroup.verifiedLongitudeMapanything,
        timeZone: eGroup.timeZone,
        description: eGroup.description,
        schedule: eGroup.schedule,
        dayOfWeek: eGroup.dayOfWeek,
        time: eGroup.time,
        demographic: eGroup.demographic,
        lastModifiedDate: eGroup.lastModifiedDate,
        leaders: eGroup.leaders,
        externalRedirectionRequired: eGroup.externalRedirectionRequired,
        externalRedirectionUrl: eGroup.externalRedirectionUrl,
    };
}

export function formatSectionData(section: any): eGroupSection {
    return {
        id: section.id,
        title: section.title,
        description: section.description || "",
        isActive: section.isActive,
        order: section.order,
        layout: getSectionLayoutData(section),
        link: section.link,
        templateEgroupSectionItems: formatSectionItem(section),
    };
}

export function formatSectionItem(section: any): eGroupSectionElement[] {
    if (section.egroupSectionItems) {
        return section.egroupSectionItems.map(
            (egroupSectionItem: any): eGroupSectionElement => {
                const item: eGroupSectionElement = {
                    id: egroupSectionItem.id,
                    title: egroupSectionItem.title,
                    description: egroupSectionItem.description || "",
                    layout: getSectionItemLayoutData(egroupSectionItem),
                    link: egroupSectionItem.link || "",
                    isActive: egroupSectionItem.isActive,
                    order: egroupSectionItem.order,
                };

                if (egroupSectionItem.query) {
                    item.query = {
                        type: egroupSectionItem.query.type,
                        content: egroupSectionItem.query.content,
                    };
                }

                if (egroupSectionItem.image) {
                    item.image = egroupSectionItem.image;
                }

                return item;
            }
        );
    }

    return [];
}

function getSectionLayoutData(section: any): SectionLayout {
    return {
        type: section.layout.type,
        showTitle: section.layout.showTitle,
        itemsToShowMobile: section.layout.itemsToShowMobile,
        itemsToShowDesktop: section.layout.itemsToShowDesktop,
        showDescription: section.layout.showDescription,
        showButton: section.layout.showButton,
        buttonText: section.layout.buttonText || "",
        externalLink: section.layout.externalLink || "",
        maxItemsToLoad: section.layout.maxItemsToLoad || "",
        collectionsGroup: section.layout.collectionsGroup || "",
    };
}

function getSectionItemLayoutData(section: any): SectionItemLayout {
    const item: SectionItemLayout = {
        showTitle: section.layout.showTitle,
        externalLink: section.layout.externalLink,
    };

    if (section.layout && section.layout.itemsToShowMobile) {
        item.itemsToShowMobile = section.layout.itemsToShowMobile;
    }

    if (section.layout && section.layout.itemsToShowDesktop) {
        item.itemsToShowDesktop = section.layout.itemsToShowDesktop;
    }

    return item;
}

export function formatEgroupType(eGroupTypeValue: any): eGroupType {
    return {
        name: eGroupTypeValue.label,
        value: eGroupTypeValue.value,
        studies: eGroupTypeValue.studies.map((study: any) => {
            return {
                name: study.label,
                value: study.value,
            };
        }),
    };
}

export function formatCollection(collectionData: any): eGroupCollection {
    return {
        id: collectionData.id,
        title: collectionData.title,
        image: collectionData.image,
        isActive: collectionData.isActive,
        order: collectionData.order,
        link: collectionData.link,
        layout: collectionData.layout,
        group: collectionData.group || "",
    };
}

export function formateGroupOrientations(
    egroupOrientations: any
): EventOrientation[] {
    if (
        egroupOrientations &&
        egroupOrientations.orientations &&
        _.isArray(egroupOrientations.orientations)
    ) {
        return egroupOrientations.orientations.map((egroupOrientation: any) => {
            return {
                id: egroupOrientation.id,
                record_type_name: egroupOrientation.record_type_name,
                address: egroupOrientation.address,
                campus_name: egroupOrientation.campus_name,
                date: egroupOrientation.date,
                time: egroupOrientation.time,
                location: egroupOrientation.location,
                type: egroupOrientation.type,
                timeZone: egroupOrientation.timeZone,
            };
        });
    }

    return [];
}

export function formatEgroupDemographic(
    eGroupDemographicList: any
): Demographic[] {
    if (eGroupDemographicList && _.isArray(eGroupDemographicList)) {
        return eGroupDemographicList.map((egroupDemographic) => {
            return {
                icon: egroupDemographic.icon,
                name: egroupDemographic.name,
                salesforce_value: egroupDemographic.salesforce_value,
                value: egroupDemographic.value,
                gender: egroupDemographic.gender
                    ? egroupDemographic.gender
                    : "",
                life_stage: egroupDemographic.life_stage,
            };
        });
    }

    return [];
}

export function formatEgroupLanguage(eGroupLanguagesList: any): Language[] {
    if (eGroupLanguagesList && _.isArray(eGroupLanguagesList)) {
        return eGroupLanguagesList.map((egroupLanguage) => {
            return {
                value: egroupLanguage.value,
                name: egroupLanguage.name,
            };
        });
    }

    return [];
}

export function formatEgroupPageFilter(eGroupPageFilters: any): eGroupFilter[] {
    const egroupPageFilterToReturn: eGroupFilter[] = [];

    if (eGroupPageFilters && _.isArray(eGroupPageFilters)) {
        eGroupPageFilters.forEach((egroupPageFilter) => {
            let orderValuesByDirection = undefined;

            if (egroupPageFilter.orderValuesBy) {
                if (egroupPageFilter.orderValuesByDirection) {
                    orderValuesByDirection =
                        egroupPageFilter.orderValuesByDirection;
                } else {
                    orderValuesByDirection = "asc";
                }
            }

            egroupPageFilterToReturn.push({
                id:
                    egroupPageFilter.name +
                    "-" +
                    Math.random().toString(36).substring(7),
                label: egroupPageFilter.label || "",
                name: egroupPageFilter.name || "",
                fields: egroupPageFilter.fields || [],
                type: egroupPageFilter.type || "",
                maxSelection: egroupPageFilter.maxSelection || undefined,
                displayType: egroupPageFilter.displayType,
                orderValuesBy: egroupPageFilter.orderValuesBy || undefined,
                orderValuesByDirection,
                values: [],
                sorter: egroupPageFilter.sorter || undefined,
                extraParams: egroupPageFilter.extraParams || null,
                order: egroupPageFilter.order,
                valuesLabel: egroupPageFilter.valuesLabel || [],
            });
        });
    }

    return egroupPageFilterToReturn;
}
