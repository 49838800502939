import { combineReducers } from "redux";

import eGroupsStore from "./egroups/";
import eTeamsStore from "./eteams/";
import baptismStore from "./baptisms";
import salvationStore from "./baptisms";
import watchPartyStore from "./watchParty";
import eventsStore from "./events";
import MainStore from "./main/";
import { BaptismsState } from "./baptisms/types";
import { EgroupsState } from "./egroups/types";
import { EteamsState } from "./eteams/types";
import { MainState } from "./main/types";
import { WatchPartyState } from "./watchParty/types";
import { SalvationsState } from "./salvations/types";
import { EventState } from "./events/types";

export type ReducerType = {
    eGroups: EgroupsState;
    baptism: BaptismsState;
    eTeams: EteamsState;
    salvations: SalvationsState;
    watchParties: WatchPartyState;
    events: EventState;
    main: MainState;
};

// Combination of the reducers and their state so they are available to be mapped to the props
let combinedReducers;
const reducers = () => {
    combinedReducers = combineReducers({
        eGroups: eGroupsStore.reducer,
        baptism: baptismStore.reducer,
        eTeams: eTeamsStore.reducer,
        salvations: salvationStore.reducer,
        watchParties: watchPartyStore.reducer,
        events: eventsStore.reducer,
        [MainStore.name]: MainStore.reducer,
    });
    return combinedReducers;
};

export default reducers;
