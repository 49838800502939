import { configureStore } from "@reduxjs/toolkit";
import createRootReducer from "./rootReducer";
import { createWrapper } from "next-redux-wrapper";

export const storeData = createRootReducer();
export type StoreDataType = typeof storeData;

export const makeStore = () =>
    configureStore({
        reducer: storeData,
        devTools: process.env.NODE_ENV !== "production",
    });
export type RootState = ReturnType<typeof makeStore>;
export const wrapper = createWrapper<ReturnType<typeof makeStore>>(makeStore, {
    debug: process.env.NODE_ENV !== "production",
});
