import { CampusLocation, CampusLocationTime } from "./types";

export function formatLocations(campuses: Array<any>): CampusLocation[] {
    return campuses.map((campus: any) => {
        return formatLocationData(campus);
    });
}

export function formatLocationData(campus: any): CampusLocation {
    return {
        id: campus.id,
        title: campus.title,
        locationName: campus.locationName,
        locationAddress: campus.locationAddress,
        locationTimes: formatLocationTimes(campus),
        locationComingSoon: campus.locationComingSoon,
        locationLatitude: parseFloat(campus.locationLatitude),
        locationLongitude: parseFloat(campus.locationLongitude),
        locationMapLink: campus.locationMapLink,
        locationIntersection: campus.locationIntersection,
        locationParking: campus.locationParking,
        locationExperiences: campus.locationExperiences,
        locationDuration: campus.locationDuration,
        locationHeaderImage: campus.locationHeaderImage,
        locationThumbnail: campus.locationThumbnail,
        link: campus.link,
        staffName: campus.staffName,
        staffEmail: campus.staffEmail,
        staffThumbnail: campus.staffThumbnail,
        sharables: campus.sharables,
        slugName: campus.slugName,
    };
}

function formatLocationTimes(campus: any): CampusLocationTime[] {
    if (campus.times) {
        return campus.times.map((time: any) => ({
            location_time: time.location_time,
            location_day: time.location_day,
        }));
    }

    return [];
}
